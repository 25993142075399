import React from 'react';
import { Link } from 'react-router-dom';
import { queryStringTimestamp } from './Utils';
import CmnContactArea from './CmnContactArea';
import { Helmet } from 'react-helmet-async';
import ScrollTopIcon from './ScrollTopIcon';
import Loading from './Loading';
import ScrollTopTran from './ScrollTopTran';
import Header from './Header';
import Footer from './Footer';

const Classroom3 = () => {
    return (
        <>
            <Helmet>
                <title>教室情報 - nijico藤沢 | nijico</title>
                <meta name="description" content="教室の様子を写真で公開しております。また、TEL・SNSの情報なども記載しております。" />
            </Helmet>
            <ScrollTopTran />
            <Loading />
            <Header />
            <main>
                <section id="classroom-visual" className="classroom2">
                    <h2>教室情報</h2>
                </section>
                <section id="panlist" className="bg-white">
                    <div className="container">
                        <ul>
                            <li className="home"><a href="/">ホーム</a>&nbsp;</li>
                            <li>＞&nbsp;教室情報</li>
                            <li>＞&nbsp;nijico藤沢</li>
                        </ul>
                    </div>
                </section>
                <section id="classroom-info-dtl">
                    <div className="container">
                        <div className="l-area">
                            <img src={"/img/img_classroom_each_03.jpg" + queryStringTimestamp()} alt="nijico藤沢の外観写真" />
                        </div>
                        <div className="r-area">
                            <div className="classroom__info">
                                <h3>nijico藤沢</h3>
                                <div className="tbl">
                                    <div className="t_row">
                                        <div className="t_lbl">TEL</div>
                                        <div className="t_txt telno">0466-53-9323</div>
                                    </div>
                                    <div className="t_row">
                                        <div className="t_lbl">FAX</div>
                                        <div className="t_txt telno">0466-53-9324</div>
                                    </div>
                                    <div className="t_row">
                                        <div className="t_lbl">住所</div>
                                        <div className="t_txt">
                                            <p>〒251-0021</p>
                                            <p>神奈川県藤沢市鵠沼神明2-1-2 <br />神明商事ビル1F</p>
                                        </div>
                                    </div>
                                    <div className="t_row">
                                        <div className="t_lbl business-hours-lbl">営業時間</div>
                                        <div className="t_txt business-hours">
                                            <p>10:00~16:00 <span className='regular-holiday'>(定休 : 日･月)</span></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="classroom_sns">
                                    <Link className="insta-link" to="https://www.instagram.com/nijicofujisawa?igsh=djlyb2Y1bDJvbG1u" target="_blank" rel="noopener noreferrer"><img src={"/img/img_classroom_instagram.png" + queryStringTimestamp()} alt="instagram-icon" /></Link>
                                    <Link className="tiktok-link" to="https://www.tiktok.com/@nijico01" target="_blank" rel="noopener noreferrer"><img src={"/img/img_classroom_tiktok.png" + queryStringTimestamp()} alt="line-icon" /></Link>
                                    <Link className="litalico-banner" to="https://h-navi.jp/support_facility/facilities/182130" target="_blank" rel="noopener noreferrer"><img src={"/img/img_litalico_classroom.png" + queryStringTimestamp()} alt="リタリコ" /></Link>
                                </div>
                            </div>
                            <div className="classroom-map">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6509.375626385415!2d139.474433!3d35.3385731!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60184e3ad789a213%3A0xfa5af88bcfc59696!2z44CSMjUxLTAwMjEg56We5aWI5bed55yM6Jek5rKi5biC6bWg5rK856We5piO77yS5LiB55uu77yR4oiS77yS77yXIOelnuaYjuWVhuS6i-ODk-ODqyAxZg!5e0!3m2!1sja!2sjp!4v1741933889869!5m2!1sja!2sjp" style={{border:0}} allowFullScreen="" loading="lazy" title='nijico藤沢のMAP'></iframe>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="classroom-gallery">
                    <div className="container">
                        <figure><img src={"/img/img_classroom_each_03-01.jpg" + queryStringTimestamp()} alt="nijico藤沢の写真01" /></figure>
                        <figure><img src={"/img/img_classroom_each_03-02.jpg" + queryStringTimestamp()} alt="nijico藤沢の写真02" /></figure>
                        <figure><img src={"/img/img_classroom_each_03-03.jpg" + queryStringTimestamp()} alt="nijico藤沢の写真03" /></figure>
                    </div>
                </section>
                <CmnContactArea />
                <ScrollTopIcon />
            </main>
            <Footer />
        </>
    )
}

export default Classroom3