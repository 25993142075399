import React from 'react';
import { Link } from 'react-router-dom';
import { queryStringTimestamp } from './Utils';
import CmnContactArea from './CmnContactArea';
import { Helmet } from 'react-helmet-async';
import ScrollTopIcon from './ScrollTopIcon';
import Loading from './Loading';
import ScrollTopTran from './ScrollTopTran';
import Header from './Header';
import Footer from './Footer';

const Classroom2 = () => {
    return (
        <>
            <Helmet>
                <title>教室情報 - nijico湘南台 | nijico</title>
                <meta name="description" content="教室の様子を写真で公開しております。また、TEL・SNSの情報なども記載しております。" />
            </Helmet>
            <ScrollTopTran />
            <Loading />
            <Header />
            <main>
                <section id="classroom-visual" className="classroom2">
                    <h2>教室情報</h2>
                </section>
                <section id="panlist" className="bg-white">
                    <div className="container">
                        <ul>
                            <li className="home"><a href="/">ホーム</a>&nbsp;</li>
                            <li>＞&nbsp;教室情報</li>
                            <li>＞&nbsp;nijico湘南台</li>
                        </ul>
                    </div>
                </section>
                <section id="classroom-info-dtl">
                    <div className="container">
                        <div className="l-area">
                            <img src={"/img/img_classroom_each_02.jpg" + queryStringTimestamp()} alt="nijico湘南台の外観写真" />
                        </div>
                        <div className="r-area">
                            <div className="classroom__info">
                                <h3>nijico湘南台</h3>
                                <div className="tbl">
                                    <div className="t_row">
                                        <div className="t_lbl">TEL</div>
                                        <div className="t_txt telno">0466-21-9840</div>
                                    </div>
                                    <div className="t_row">
                                        <div className="t_lbl">FAX</div>
                                        <div className="t_txt telno">0466-21-9841</div>
                                    </div>
                                    <div className="t_row">
                                        <div className="t_lbl">住所</div>
                                        <div className="t_txt">
                                            <p>〒252-0804</p>
                                            <p>神奈川県藤沢市湘南台2-26-18 <br />サザンヒル1F</p>
                                        </div>
                                    </div>
                                    <div className="t_row">
                                        <div className="t_lbl business-hours-lbl">営業時間</div>
                                        <div className="t_txt business-hours">
                                            <p>10:00~17:15 <span className='regular-holiday'>(定休 : 日･月)</span></p>
                                            <span className='school-holidays'>※学校休校日 10:00～16:30</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="classroom_sns">
                                    <Link className="insta-link" to="https://www.instagram.com/nijico_shonandai/" target="_blank" rel="noopener noreferrer"><img src={"/img/img_classroom_instagram.png" + queryStringTimestamp()} alt="instagram-icon" /></Link>
                                    <Link className="tiktok-link" to="https://www.tiktok.com/@nijico01" target="_blank" rel="noopener noreferrer"><img src={"/img/img_classroom_tiktok.png" + queryStringTimestamp()} alt="line-icon" /></Link>
                                    <Link className="litalico-banner" to="https://h-navi.jp/support_facility/facilities/162198" target="_blank" rel="noopener noreferrer"><img src={"/img/img_litalico_classroom.png" + queryStringTimestamp()} alt="リタリコ" /></Link>
                                </div>
                            </div>
                            <div className="classroom-map">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3252.479900872766!2d139.46578739999998!3d35.393353499999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60185154e82aebbd%3A0xad1e0685debae0f9!2zbmlqaWNv5rmY5Y2X5Y-w!5e0!3m2!1sja!2sjp!4v1684462426275!5m2!1sja!2sjp" style={{border:0}} allowFullScreen="" loading="lazy" title='nijico湘南台のMAP'></iframe>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="classroom-gallery">
                    <div className="container">
                        <figure><img src={"/img/img_classroom_each_02-01.jpg" + queryStringTimestamp()} alt="nijico湘南台の写真01" /></figure>
                        <figure><img src={"/img/img_classroom_each_02-02.jpg" + queryStringTimestamp()} alt="nijico湘南台の写真02" /></figure>
                        <figure><img src={"/img/img_classroom_each_02-03.jpg" + queryStringTimestamp()} alt="nijico湘南台の写真03" /></figure>
                        <figure><img src={"/img/img_classroom_each_02-04.jpg" + queryStringTimestamp()} alt="nijico湘南台の写真04" /></figure>
                        <figure><img src={"/img/img_classroom_each_02-05.jpg" + queryStringTimestamp()} alt="nijico湘南台の写真05" /></figure>
                        <figure><img src={"/img/img_classroom_each_02-06.jpg" + queryStringTimestamp()} alt="nijico湘南台の写真06" /></figure>
                    </div>
                </section>
                <CmnContactArea />
                <ScrollTopIcon />
            </main>
            <Footer />
        </>
    )
}

export default Classroom2