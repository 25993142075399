import React from 'react'
import { queryStringTimestamp } from './Utils'
import { Link } from 'react-router-dom'

const CmnContactArea = () => {
    return (
        <>
            <section id="contact">
                <div className="container">
                    <h2>お問い合わせ</h2>
                    <div className="tel_mail_box">
                        <div className="telephone">
                            <div className="icon_area"><img src={"/img/img_contact_phone.png"+ queryStringTimestamp()} alt="icon_phone" /></div>
                            <div className="inner">
                                <p className="sub-title">お電話でのお問い合わせ</p>
                                <div className="tel-no"><span>【代表】</span><a href="tel:0466476730">0466-47-6730</a></div>
                                <p className="supplement tsuku-medium">電話受付 10:00～17:00(定休 : 日･月)</p>
                            </div>
                        </div>
                        <div className="mail">
                            <div className="icon_area"><img src={"/img/img_contact_mail.png" + queryStringTimestamp()} alt="icon_mail" /></div>
                            <div className="inner">
                                <p>各種お問い合わせ<span className="text">はこちら</span></p>
                                <Link to="/contact">お問い合わせフォームへ</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CmnContactArea