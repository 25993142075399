import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async'
import CmnContactArea from './CmnContactArea';
import { queryStringTimestamp } from './Utils';
import { Link } from 'react-router-dom';
import {useWindowSize} from 'react-use';
import ScrollTopIcon from './ScrollTopIcon';
import Loading from './Loading';
import Header from './Header';
import Footer from './Footer';
import ScrollTopTran from './ScrollTopTran';

const Home = () => {
    const [videoSource, setVideoSource] = useState('');
    const { width } = useWindowSize();

    useEffect(() => {
        if (width > 500) {
            setVideoSource('/video/video_pc_1080p-h265-500.mp4' + queryStringTimestamp());
        } else {
            setVideoSource('/video/video_sp_1080p-h265-500.mp4' + queryStringTimestamp());
        }
    }, [width]);

    return (
        <>
            <Helmet>
                <title>nijico | 児童発達支援・放課後等デイサービス</title>
                <meta name="description" content="nijicoは、虹のようにそれぞれの個性を尊重し、大事なお子さまたちの明るい未来を創るお手伝いをする多機能型事業所です。" />
            </Helmet>
            <ScrollTopTran />
            <Loading />
            <Header />
            <main>
                <section id='visual'>
                    <div className="container">
                        <video id="video" loop muted autoPlay playsInline preload="auto" poster={"/img/img_video_poster.png" + queryStringTimestamp()}  src={videoSource} type="video/mp4" />
                    </div>
                </section>
                <section id="classroom-info">
                    <div className="container">
                        <div className="title">
                            <h2>教室情報</h2>
                        </div>
                        <div className="contents">
                            <div className="classroom">
                                <Link className="pic" to="/classroom/classroom1">
                                    <img src={"/img/img_classroom_01.jpg" + queryStringTimestamp()} alt="nijico辻堂駅前の外観写真" />
                                    <span>もっと見る<i className="fa-solid fa-circle-right"></i></span>
                                </Link>
                                <div className="classroom__info">
                                    <div className="sub-title">
                                        <h3>nijico辻堂駅前</h3>
                                    </div>
                                    <div className="tbl">
                                        <div className="t_row">
                                            <div className="t_lbl">TEL</div>
                                            <div className="t_txt telno">0466-47-6730</div>
                                        </div>
                                        <div className="t_row">
                                            <div className="t_lbl">住所</div>
                                            <div className="t_txt">
                                                <p>〒251-0047</p>
                                                <p> 神奈川県藤沢市辻堂2-7-16 <span className="text">SHONAN SAKURAI BUILLDING 3F</span></p>
                                                <Link className="g-map" to="https://goo.gl/maps/cFoeRyTcdwSxE43L8" target="_blank" rel="noopener noreferrer">Google Map <i className="fa-solid fa-location-dot"></i></Link>
                                            </div>
                                        </div>
                                        <div className="t_row">
                                            <div className="t_lbl business-hours-lbl">営業時間</div>
                                            <div className="t_txt business-hours">10:00~17:15 <span className='regular-holiday'>(定休 : 日･月)</span></div>
                                        </div>
                                    </div>
                                    <div className="classroom_sns">
                                        <Link className="insta-link" to="https://www.instagram.com/nijico_tsujido/" target="_blank" rel="noopener noreferrer"><img src={"/img/img_classroom_instagram.png" + queryStringTimestamp()} alt="instagram-icon" /></Link>
                                        <Link className="tiktok-link" to="https://www.tiktok.com/@nijico01" target="_blank" rel="noopener noreferrer"><img src={"/img/img_classroom_tiktok.png" + queryStringTimestamp()} alt="tiktok-icon" /></Link>
                                        <Link className="litalico-banner" to="https://h-navi.jp/support_facility/facilities/160567" target="_blank" rel="noopener noreferrer"><img src={"/img/img_litalico_classroom.png" + queryStringTimestamp()} alt="リタリコ" /></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="classroom ">
                                <Link className="pic" to="/classroom/classroom2">
                                    <img src={"/img/img_classroom_02.jpg" + queryStringTimestamp()} alt="nijico湘南台の外観写真" />
                                    <span>もっと見る<i className="fa-solid fa-circle-right"></i></span>
                                </Link>
                                <div className="classroom__info">
                                    <div className="sub-title">
                                        <h3>nijico湘南台</h3>
                                    </div>
                                    <div className="tbl">
                                        <div className="t_row">
                                            <div className="t_lbl">TEL</div>
                                            <div className="t_txt telno">0466-21-9840</div>
                                        </div>
                                        <div className="t_row">
                                            <div className="t_lbl">住所</div>
                                            <div className="t_txt">
                                                <p>〒252-0804</p>
                                                <p>神奈川県藤沢市湘南台2-26-18 <span className='text'>サザンヒル1F</span></p>
                                                <Link className="g-map" to="https://goo.gl/maps/VnqaPsDeMNzDcASo7" target="_blank" rel="noopener noreferrer">Google Map <i className="fa-solid fa-location-dot"></i></Link>
                                            </div>
                                        </div>
                                        <div className="t_row">
                                            <div className="t_lbl business-hours-lbl">営業時間</div>
                                            <div className="t_txt business-hours">10:00~17:15 <span className='regular-holiday'>(定休 : 日･月)</span></div>
                                        </div>
                                    </div>
                                    <div className="classroom_sns">
                                        <Link className="insta-link" to="https://www.instagram.com/nijico_shonandai/" target="_blank" rel="noopener noreferrer"><img src={"/img/img_classroom_instagram.png" + queryStringTimestamp()} alt="instagram-icon" /></Link>
                                        <Link className="tiktok-link" to="https://www.tiktok.com/@nijico01" target="_blank" rel="noopener noreferrer"><img src={"/img/img_classroom_tiktok.png" + queryStringTimestamp()} alt="tiktok-icon" /></Link>
                                        <Link className="litalico-banner" to="https://h-navi.jp/support_facility/facilities/162198" target="_blank" rel="noopener noreferrer"><img src={"/img/img_litalico_classroom.png" + queryStringTimestamp()} alt="リタリコ" /></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="classroom ">
                                <Link className="pic" to="/classroom/classroom3">
                                    <img src={"/img/img_classroom_03.jpg" + queryStringTimestamp()} alt="nijico藤沢の外観写真" />
                                    <span>もっと見る<i className="fa-solid fa-circle-right"></i></span>
                                </Link>
                                <div className="classroom__info">
                                    <div className="sub-title">
                                        <h3>nijico藤沢</h3>
                                    </div>
                                    <div className="tbl">
                                        <div className="t_row">
                                            <div className="t_lbl">TEL</div>
                                            <div className="t_txt telno">0466-53-9323</div>
                                        </div>
                                        <div className="t_row">
                                            <div className="t_lbl">住所</div>
                                            <div className="t_txt">
                                                <p>〒251-0021</p>
                                                <p>神奈川県藤沢市鵠沼神明2-1-2 <span className='text'>神明商事ビル1F</span></p>
                                                <Link className="g-map" to="https://maps.app.goo.gl/48PjnrX87Pdh1ycDA" target="_blank" rel="noopener noreferrer">Google Map <i className="fa-solid fa-location-dot"></i></Link>
                                            </div>
                                        </div>
                                        <div className="t_row">
                                            <div className="t_lbl business-hours-lbl">営業時間</div>
                                            <div className="t_txt business-hours">10:00~16:00 <span className='regular-holiday'>(定休 : 日･月)</span></div>
                                        </div>
                                    </div>
                                    <div className="classroom_sns fujisawa">
                                        <Link className="insta-link" to="https://www.instagram.com/nijicofujisawa?igsh=djlyb2Y1bDJvbG1u" target="_blank" rel="noopener noreferrer">
                                            <img src={"/img/img_classroom_instagram.png" + queryStringTimestamp()} alt="instagram-icon" />
                                        </Link>
                                        <Link className="tiktok-link" to="https://www.tiktok.com/@nijico01" target="_blank" rel="noopener noreferrer">
                                            <img src={"/img/img_classroom_tiktok.png" + queryStringTimestamp()} alt="tiktok-icon" />
                                        </Link>
                                        <Link className="litalico-banner" to="https://h-navi.jp/support_facility/facilities/182130" target="_blank" rel="noopener noreferrer">
                                            <img src={"/img/img_litalico_classroom.png" + queryStringTimestamp()} alt="リタリコ" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="trouble">
                    <div className="container">
                        <div className="title">
                            こんな<span className="emphasis">育児のお悩み</span>､<br />抱えていませんか？
                        </div>
                        <picture>
                            <source srcSet={"/img/img_trouble_01.png" + queryStringTimestamp()} media="(min-width: 769px)"/>{/* 幅1000px以上なら表示 */}
                            <source srcSet={"/img/img_trouble_01_sp.png" + queryStringTimestamp()} media="(max-width: 768px)"/>
                            <img src={"/img/img_trouble_01.png" + queryStringTimestamp()} alt="育児のお悩み" />
                        </picture>
                        <div className="under">
                            <div className="wrap">
                                そのお悩み､<br /><img src={"/img/img_nijico.png"} alt="nijico" />に<span className="text">ご相談ください！</span>
                            </div>
                            <img className='staff' src={"/img/img_trouble_staff_01.png"} alt="スタッフ" />
                        </div>
                    </div>
                </section>
                <section id="about">
                    <div className="container">
                        <h2>nijicoについて</h2>
                        <div className="inner">
                            <div className="wrap">
                                <figure><img src={"/img/img_about1.png" + queryStringTimestamp()} alt="子供の画像" /></figure>
                                <div className='catchphrase'>
                                    <p><img src={"/img/img_about_niji.png"} alt="虹" />のように</p>
                                    <p>輝く将来の為に</p>
                                </div>
                            </div>
                            <div className="top tsuku-bold">
                                <p>「nijico」とは虹のようにそれぞれの個性を尊重し、</p>
                                <p>大事なお子さまたちの明るい未来を創る</p>
                                <p>お手伝いをする多機能型事業所です。</p><br />
                                <p>子どもたちが虹のように輝く将来を見据え、</p>
                                <p>少しでも多くのことが一人で出来るように、</p>
                                <p>各体験や学びを多く出来る施設運営を<span className='text'>目指しております。</span></p><br />
                                <p>児童発達支援・放課後等デイサービス・<span className='text'>障害児相談支援の制度を活用し、</span></p>
                                <p>お子さまだけでなく保護者の皆様の不安や悩みを、<span className='text'>解消出来ればと考えております。</span></p><br />
                                <p>お子さまの将来を第一に考え、</p>
                                <p>ひとりひとりの個性と真摯に向き合うことで、</p>
                                <p>他の事業所にはない療育を行える事業所と<span className='text'>なっております。</span></p>
                                <figure id='imgImportant'><img src={"/img/img_important.png" + queryStringTimestamp()} alt="nijicoが大切にしていること" /></figure>
                                <Link className="about_support" to="/about"><img src={"/img/img_about_support.png" + queryStringTimestamp()} alt="発達支援・放デイについて" /></Link>
                                <img className="rainbow" src={"/img/img_about_rainbow.png" + queryStringTimestamp()} alt="光る星" /> 
                            </div>
                        </div>
                    </div>
                </section>
                <section id="character">
                    <div className="container">
                        <h2>nijicoの<span className="text">｢とりくみ｣</span></h2>
                        <figure><img src={"/img/img_character_title.png" + queryStringTimestamp()} alt="手厚いサポートプログラム" /></figure>
                        <div className="elements">
                            <img className="airplane" src={"/img/img_character_airplane.png" + queryStringTimestamp()} alt="飛行機のイラスト" />
                            <img className="flower" src={"/img/img_character_flower.png" + queryStringTimestamp()} alt="花のイラスト" />
                            <Link to="/attempt">
                                <picture>
                                    <source media="(min-width: 500px)" srcSet={"/img/img_character_elements.png" + queryStringTimestamp()} />
                                    <source media="(max-width: 500px)" srcSet={"/img/img_character_elements_sp.png" + queryStringTimestamp()} />
                                    <img src={"/img/img_character_elements.png" + queryStringTimestamp()} alt="nijicoのサポートプログラム" />
                                </picture>
                            </Link>
                        </div>
                        <p className="tsuku-bold">
                            <span className="text">失敗や成功をする「体験」を</span><span className="text">一緒にしながら経験し</span><br />
                            <span className="text">子供たちの自信に繋げるお手伝いが</span><span className="text">出来ればと考えております。</span>
                        </p>
                        <Link className="morebtn" to="/attempt">詳細はこちら<span><i className="fa-solid fa-chevron-right"></i></span></Link>
                    </div>
                </section>
                <section id="guide">
                    <div className="container">
                        <h2>入所のご案内</h2>
                        <div className="elements">
                            <div className="element">
                                <span className="step">STEP-01</span>
                                <figure><img src={"/img/img_flow_star1.png" + queryStringTimestamp()} alt="STEP-01" /></figure>
                                <div className="stepTitle">お問い合わせ</div>
                                <p className="tsuku-medium"><Link to="/contact">ご相談は<br />こちらから</Link></p>
                            </div>
                            <div className="element">
                                <span className="step">STEP-02</span>
                                <figure><img src={"/img/img_flow_star2.png" + queryStringTimestamp()} alt="STEP-02" /></figure>
                                <div className="stepTitle">見学・体験</div>
                                <p className="tsuku-medium">30分程度<br />（説明含みます）</p>
                            </div>
                            <div className="element">
                                <span className="step">STEP-03</span>
                                <figure><img src={"/img/img_flow_star3.png" + queryStringTimestamp()} alt="STEP-03" /></figure>
                                <div className="stepTitle">受給者証発行<br />・手続き</div>
                                <p className="tsuku-medium">区･市役所で発行<br />（２週間程度）</p>
                            </div>
                            <div className="element">
                                <span className="step">STEP-04</span>
                                <figure><img src={"/img/img_flow_star4.png" + queryStringTimestamp()} alt="STEP-04" /></figure>
                                <div className="stepTitle">入所申込</div>
                                <p className="tsuku-medium">施設のご利用方法等を<br />ご説明いたします。</p>
                            </div>
                            <div className="element">
                                <span className="step">STEP-05</span>
                                <figure><img src={"/img/img_flow_star5.png" + queryStringTimestamp()} alt="STEP-05" /></figure>
                                <div className="stepTitle">ご利用開始</div>
                                <p className="tsuku-medium">｢nijico｣の<br />新たな仲間です！</p>
                            </div>
                        </div>
                        <div className="next_guide">
                            <p className="tsuku-bold">費用、営業時間等の詳細はこちら</p>
                            <Link className="genJyuu-bold" to="/guide">ご利用ガイドへ<span><i className="fa-solid fa-chevron-right"></i></span></Link>
                        </div>
                    </div>
                </section>
                <CmnContactArea />
                <ScrollTopIcon />
            </main>
            <Footer />
        </>
    )
}

export default Home