import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import Classroom1 from "./components/Classroom1";
import Classroom2 from "./components/Classroom2";
import Classroom3 from "./components/Classroom3";
import Guide from "./components/Guide";
import Attempt from "./components/Attempt";
import Recruit from "./components/Recruit";
import Company from "./components/Company";
import About from "./components/About";
import Contact from "./components/Contact";
import Error from "./components/Error";
import NotFound from "./components/NotFound";

function App() {
    return (
        <>
            <Router>
                <Routes>
                    <Route path='/' element={ <Home /> } />
                    <Route path='/classroom/classroom1' element={ <Classroom1 /> } />
                    <Route path='/classroom/classroom2' element={ <Classroom2 /> } />
                    <Route path='/classroom/classroom3' element={ <Classroom3 /> } />
                    <Route path='/guide' element={ <Guide /> } />
                    <Route path='/attempt' element={ <Attempt /> } />
                    <Route path='/recruit' element={ <Recruit /> } />
                    <Route path='/company' element={ <Company /> } />
                    <Route path='/about' element={ <About /> } />
                    <Route path='/contact' element={ <Contact /> } />
                    <Route path='/error' element={ <Error /> } />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </Router>
        </>
    );
}

export default App;
